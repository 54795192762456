import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../styles/LeaveCalendarPage.css';
import '../styles/LeaveTablePage.css';

const localizer = momentLocalizer(moment);
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const LeaveCalendarPage = () => {
  const [users, setUsers] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [view, setView] = useState('calendar'); // Toggle between 'calendar' and 'table'
  const [leaveDetails, setLeaveDetails] = useState([]);
  const [showUserList, setShowUserList] = useState(false); // User list is shown by default on larger screens
  const [filters, setFilters] = useState({
    status: '',
    unit: '',
    location: '',
    purpose: ''
  });

  useEffect(() => {
    fetchUsers();
    fetchLeaveDetails(); // Fetch leave details for the table view
  }, []);

  useEffect(() => {
    if (selectedUsers.length > 0) {
      fetchLeaves();
    }
  }, [selectedUsers]);

  const fetchUsers = async () => {
    const { data } = await axios.get(`${API_BASE_URL}/api/users/user-details`);
    console.log('Fetched Users:', data);
    const usersWithColor = data.map(user => ({
      ...user,
      color: '#' + Math.floor(Math.random() * 16777215).toString(16),
    }));
    setUsers(usersWithColor);
  };

  const fetchLeaves = async () => {
    const { data } = await axios.post(`${API_BASE_URL}/api/users/leave-dates`, {
      userIds: selectedUsers,
    });
    console.log('Fetched Leaves:', data); 
    setEvents(data.map(leave => ({
      id: leave.userId,
      title: `${leave.purpose}- ${leave.firstName} ${leave.lastName}`,
      start: new Date(leave.fromDate),
      end: new Date(leave.toDate),
      color: users.find(user => user._id === leave.userId)?.color || '#000',
    })));
  };

  const fetchLeaveDetails = async () => {
    const { data } = await axios.get(`${API_BASE_URL}/api/users/leave-details`);
    setLeaveDetails(data);
  };

  const handleUserSelect = (userId) => {
    const isAlreadySelected = selectedUsers.includes(userId);
    if (isAlreadySelected) {
      setSelectedUsers(selectedUsers.filter(id => id !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value
    });
  };

  const filteredUsers = users.filter(user =>
    user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.lastName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredLeaves = leaveDetails.filter(leave => {
    return (
      (!filters.status || leave.status === filters.status) &&
      (!filters.unit || leave.unit === filters.unit) &&
      (!filters.location || leave.location === filters.location) &&
      (!filters.purpose || leave.purpose === filters.purpose) &&
      (leave.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
       leave.lastName.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  });

  const eventsToShow = events.filter(event => selectedUsers.includes(event.id));

  const handleDownloadReport = async () => {
    const response = await axios({
      url: `${API_BASE_URL}/api/users/download-leave-report`,
      method: 'GET',
      responseType: 'blob',
    });

    const file = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const fileURL = URL.createObjectURL(file);
    const fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute('download', 'LeaveReport.xlsx');
    document.body.appendChild(fileLink);
    fileLink.click();
    document.body.removeChild(fileLink);
  };

  return (  
    <div className="leave-page-container">
      <div className="top-bar-state">
        <button onClick={() => setView(view === 'calendar' ? 'table' : 'calendar')} className="toggle-view-button">
          Switch to {view === 'calendar' ? 'Table' : 'Calendar'} View
        </button>
        {view === 'calendar' && (
          <button onClick={() => setShowUserList(!showUserList)} className="toggle-user-list-button">
            {showUserList ? 'Hide' : 'Show'} User List
          </button>
        )}
      </div>
      <div className="leave-calendar-container">
        {view === 'calendar' ? (
          <>
            <Calendar
              localizer={localizer}
              events={eventsToShow}
              startAccessor="start"
              endAccessor="end"
              eventPropGetter={(event) => ({
                style: { backgroundColor: event.color },
              })}
              style={{ height: 'calc(100% - 1px)', width: '100%' }}
            />
            <div className={`user-list-container ${showUserList ? 'show' : 'hide'}`}>
              <input
                type="text"
                className="search-box"
                placeholder="Search user..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <div className="user-list">
                {filteredUsers.map(user => (
                  <div
                    key={user._id}
                    className={`user-item ${selectedUsers.includes(user._id) ? 'selected' : ''}`}
                    onClick={() => handleUserSelect(user._id)}
                    style={{ backgroundColor: selectedUsers.includes(user._id) ? user.color : '' }}
                  >
                    <img src={`${API_BASE_URL}/${user.profileImage}`} alt={user.firstName} className="profile-pic" />
                    {user.firstName} {user.lastName}
                  </div>
                ))}
              </div>
              <button onClick={handleDownloadReport} className="download-report-button">
                Download Report
              </button>
            </div>
          </>
        ) : (
          <div className="leave-table-container">
            <div className="table-filters">
              <input
                type="text"
                placeholder="Search by name..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <select name="status" value={filters.status} onChange={handleFilterChange}>
                <option value="">All Statuses</option>
                <option value="อนุมัติ">Approved</option>
                <option value="รออนุมัติ">Pending</option>
                <option value="ไม่อนุมัติ">Declined</option>
              </select>
              <select name="unit" value={filters.unit} onChange={handleFilterChange}>
                <option value="">All Units</option>
                <option value="1101 Hospitality Business Unit">1101 Hospitality Business Unit</option>
                <option value="1102 Entertainment Business Unit">1102 Entertainment Business Unit</option>
                <option value="1103 Events & Exhibitions Business Unit">1103 Events & Exhibitions Business Unit</option>
                <option value="2204 Strategy & Business Solutions Unit">2204 Strategy & Business Solutions Unit</option>
                <option value="2205 Corporate Culture Development">2205 Corporate Culture Development</option>
                <option value="2206 Finance & Accounting">2206 Finance & Accounting</option>
                <option value="MD">MD</option>
              </select>
              <select name="location" value={filters.location} onChange={handleFilterChange}>
                <option value="">All Locations</option>
                <option value="อาคารสรชัย">อาคารสรชัย</option>
                <option value="The Forestias (บางนา)">The Forestias (บางนา)</option>
              </select>
              <select name="purpose" value={filters.purpose} onChange={handleFilterChange}>
                <option value="">All Purposes</option>
                <option value="ลาป่วย">ลาป่วย</option>
                <option value="ลากิจ">ลากิจ</option>
                <option value="ลาพักผ่อนประจำปี">ลาพักผ่อนประจำปี</option>
                <option value="ลาเพื่อคลอดบุตร">ลาเพื่อคลอดบุตร</option>
                <option value="ลาเพื่อทำหมัน">ลาเพื่อทำหมัน</option>
                <option value="ลาเพื่อรับราชการทหาร">ลาเพื่อรับราชการทหาร</option>
                <option value="ลาเพื่อการฝึกอบรม">ลาเพื่อการฝึกอบรม</option>
                <option value="ลาเพื่ออุปสมบท">ลาเพื่ออุปสมบท</option>
              </select>
            </div>
            <table className="leave-table">
              <thead>
                <tr>
                  <th className="hide-on-small">#</th>
                  <th>ชื่อ-นามสกุล</th>
                  <th>ประเภทการลา</th>
                  <th className="hide-on-small">วันที่ยื่น</th>
                  <th>วันที่เริ่มต้นลา</th>
                  <th>วันที่ลาถึง</th>
                  <th className="hide-on-small">Download</th>
                  <th className="hide-on-small">Location</th>
                  <th className="hide-on-small">หน่วยงาน</th>
                  <th>Status</th>
                  <th className="hide-on-small">Approve Time</th>
                  <th className="hide-on-small">Supervisor Email</th>
                </tr>
              </thead>
              <tbody>
                {filteredLeaves.map((leave, index) => (
                  <tr key={leave._id}>
                    <td className="hide-on-small">{index + 1}</td>
                    <td>{`${leave.firstName} ${leave.lastName}`}</td>
                    <td>{leave.purpose}</td>
                    <td className="hide-on-small">{leave.createdAt}</td>
                    <td>{leave.fromDate}</td>
                    <td>{leave.toDate}</td>
                    <td className="hide-on-small">
                      {leave.filePath ? (
                        <a href={`${API_BASE_URL}/${leave.filePath}`} target="_blank" rel="noopener noreferrer">
                          Download
                        </a>
                      ) : (
                        'No file'
                      )}
                    </td>
                    <td className="hide-on-small">{leave.location}</td>
                    <td className="hide-on-small">{leave.unit}</td>
                    <td style={{ color: getStatusColor(leave.status) }}>{leave.status}</td>
                    <td className="hide-on-small">{leave.approvalTime}</td>
                    <td className="hide-on-small">{leave.supervisorEmail}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

const getStatusColor = (status) => {
  switch (status) {
    case 'อนุมัติ':
      return 'green';
    case 'รออนุมัติ':
      return 'orange';
    case 'ไม่อนุมัติ':
      return 'red';
    default:
      return 'black';
  }
};

export default LeaveCalendarPage;
