import React, { useState } from 'react';
import  '../../styles/Sidebar.css';
import  '../../styles/Dashboard.css';
import ManHourStatistics from '../../components/ManHourStatistics';

function ManHourStatisticsPage() {
    return (
        <div className="dashboard-layout"> 
            <ManHourStatistics />
        </div> 
    );
}

export default ManHourStatisticsPage;
