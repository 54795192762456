import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'; // Add Navigate to the import
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import AdminManagerPage from './pages/AdminManagerPage';
import UserStatePage from './pages/UserStatePage';
import ProtectedRoute from './services/ProtectedRoute.js';
import ProjectAssignment from './pages/ProjectAssignment';
import MyCampaignsPages from './pages/MyCampaigns';
import AddBusinessPage from './pages/AddBusiness';
import UserProfile from './pages/ProFilePage';
import Information_Center from './pages/Information-center';
import LeaveApproval from './pages/LeaveApproval';
import RequestForms from './pages/RequestForms';
import RequestFormReviewPage from './pages/RequestFormReview';
import Evaluations from './pages/Evaluations';
import ManHourStatisticsPage from './pages/ManHourStatisticsPage';
import UserTimesheetPage from './pages/UserTimesheetPage';

import Layout from "./pages/layouts";



function App() {
   
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route element={<Layout />}>
          <Route path="/dashboard" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>} />
          <Route path="/dashboard/adminmanager" element={<ProtectedRoute requiredRole={['ADMIN-CCD',"ADMIN-CCD-TECH","CEO"]}><AdminManagerPage /></ProtectedRoute>} />
          <Route path="/dashboard/User-State" element={<ProtectedRoute requiredRole={["ADMIN-CCD-TECH","CEO","STAT-VIEW"]}><UserStatePage /></ProtectedRoute>} />
          <Route path="/dashboard/ProjectAssignment" element={<ProtectedRoute requiredRole={['ADMIN-CCD-TECH',"CEO","PROJECT-CHAMPION","MD"]}><ProjectAssignment /></ProtectedRoute>} />
          <Route path="/dashboard/LeaveApproval" element={<ProtectedRoute requiredRole={['ADMIN-CCD',"ADMIN-CCD-TECH","CEO","HEAD"]}><LeaveApproval /></ProtectedRoute>} />
          <Route path="/dashboard/My-Campaigns" element={<ProtectedRoute><MyCampaignsPages /></ProtectedRoute>} />
          <Route path="/dashboard/Add-Business" element={<ProtectedRoute requiredRole={['ADMIN-CCD-TECH',"CEO"]}><AddBusinessPage /></ProtectedRoute>} />
          <Route path="/dashboard/Profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
          <Route path="/dashboard/information-center" element={<ProtectedRoute><Information_Center /></ProtectedRoute>} />
          <Route path="/dashboard/Request-Forms" element={<ProtectedRoute><RequestForms /></ProtectedRoute>} />
          <Route path="/dashboard/Request-Review" element={<ProtectedRoute requiredRole={["ADMIN-CCD-TECH","CEO","STAT-VIEW"]}><RequestFormReviewPage /></ProtectedRoute>} />
          <Route path="/dashboard/Evaluations" element={<ProtectedRoute><Evaluations /></ProtectedRoute>} />
          <Route path="/dashboard/manhour-stats" element={<ProtectedRoute requiredRole={["ADMIN-CCD-TECH","CEO","STAT-VIEW"]}><ManHourStatisticsPage /></ProtectedRoute>} />
          <Route path="/timesheet/:userId" element={<ProtectedRoute requiredRole={["ADMIN-CCD-TECH","CEO","STAT-VIEW"]}><UserTimesheetPage /></ProtectedRoute>} />
          
          
          {/* /My-Campaigns" */}
        </Route>
        {/* Redirect users to the login page if they try to access an undefined route */}
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
