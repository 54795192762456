import React, { useState, useEffect } from 'react';
import { Tabs, Card, Avatar, List, Divider, Input, Select, Table, Tag } from 'antd';
import { Line } from 'react-chartjs-2';
import '../styles/ManHourStatisticsPage.css';
import moment from 'moment';
import { Link } from 'react-router-dom';
import axios from 'axios';

import {
    Chart as ChartJS,
    CategoryScale, // Import CategoryScale
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    LineElement,
    PointElement,
  } from 'chart.js';
  
  // Register the scales, elements, and plugins
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  );
const { TabPane } = Tabs;
const { Option } = Select;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const topPerformersMockData = [
  { name: 'John Doe', score: 98, imgUrl: 'https://via.placeholder.com/40' },
  { name: 'Jane Smith', score: 95, imgUrl: 'https://via.placeholder.com/40' },
  { name: 'Alice Johnson', score: 93, imgUrl: 'https://via.placeholder.com/40' },
];

const upcomingProjectsMockData = [
  { title: 'Project A', description: 'Due in 2 weeks' },
  { title: 'Project B', description: 'Due in 1 month' },
  { title: 'Project C', description: 'Due in 3 months' },
];

const productivityMockData = [
  {
    key: '1',
    name: 'John Doe',
    position: 'Developer',
    business: 'Tech',
    totalHours: 40,
    totalJobs: 5,
    date: [
      {
        date: { $date: '2024-10-15T00:00:00.000Z' },
        records: [
          {
            jobNumber: 'Project 1',
            task: 'Development',
            workingHours: 8,
            remark: 'Finished frontend module',
          },
        ],
      },
      {
        date: { $date: '2024-10-10T00:00:00.000Z' },
        records: [
          {
            jobNumber: 'Project 1',
            task: 'Testing',
            workingHours: 6,
            remark: 'Tested module',
          },
        ],
      },
    ],
    productivityChange: 10,
    productivityScores: [80, 85, 88, 90, 92, 95, 98],
  },
  {
    key: '2',
    name: 'Jane Smith',
    position: 'Designer',
    business: 'Creative',
    totalHours: 38,
    totalJobs: 4,
    date: [
      {
        date: { $date: '2024-10-02T00:00:00.000Z' },
        records: [
          {
            jobNumber: 'Project 2',
            task: 'Design',
            workingHours: 7,
            remark: 'Designed main UI',
          },
        ],
      },
      {
        date: { $date: '2024-10-04T00:00:00.000Z' },
        records: [
          {
            jobNumber: 'Project 2',
            task: 'Illustration',
            workingHours: 5,
            remark: 'Created illustrations',
          },
        ],
      },
    ],
    productivityChange: -5,
    productivityScores: [90, 88, 86, 85, 82, 80, 78],
  },
  
  {
    key: '3',
    name: 'Alice Johnson',
    position: 'Manager',
    business: 'Operations',
    totalHours: 42,
    totalJobs: 6,
    date: [
      {
        date: { $date: '2024-09-28T00:00:00.000Z' },
        records: [
          {
            jobNumber: 'Project 3',
            task: 'Coordination',
            workingHours: 6,
            remark: 'Coordinated project team',
          },
        ],
      },
      {
        date: { $date: '2024-10-03T00:00:00.000Z' },
        records: [
          {
            jobNumber: 'Project 3',
            task: 'Meeting',
            workingHours: 5,
            remark: 'Meeting with client',
          },
        ],
      },
    ],
    productivityChange: 0,
    productivityScores: [95, 95, 95, 95, 95, 95, 95],
  },
];

const ManHourStatistics = () => {
  const [activeTab, setActiveTab] = useState('famz');
  // const [filteredData, setFilteredData] = useState(productivityMockData);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPosition, setSelectedPosition] = useState('');
  const [selectedBusiness, setSelectedBusiness] = useState('');
  const [positions, setPositions] = useState([]);
  const [businesses, setBusinesses] = useState([]);

  // useEffect(() => {
  //   const filtered = productivityMockData.filter(
  //     (item) =>
  //       item.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
  //       (selectedPosition ? item.position === selectedPosition : true) &&
  //       (selectedBusiness ? item.business === selectedBusiness : true)
  //   );
  //   setFilteredData(filtered);
  // }, [searchTerm, selectedPosition, selectedBusiness]);
  const hasSubmittedForCurrentWeek = (timesheetDates) => {
    if (!timesheetDates || !Array.isArray(timesheetDates)) {
      return false; // If the dates array is undefined or not an array, return false
    }
  
    const startOfWeek = moment().startOf('isoWeek'); // Monday
    const endOfWeek = moment().endOf('isoWeek'); // Sunday
  
    return timesheetDates.some(dateObj => {
      const timesheetDate = moment(dateObj.date.$date);
      return timesheetDate.isBetween(startOfWeek, endOfWeek, null, '[]'); // Check if the date is in the current week
    });
  };
  useEffect(() => {
    // Fetch current week's timesheet data
    const fetchTimesheets = async () => {
      try {
          const response = await axios.get(`${API_BASE_URL}/api/timesheet/current-week-manhour`, {
              withCredentials: true,
          });
          setFilteredData(response.data);
      } catch (error) {
          console.error("Error fetching timesheet data:", error);
      }
  };
  
  const fetchFilters = async () => {
      try {
          const response = await axios.get(`${API_BASE_URL}/api/timesheet/filters-manhour`, {
              withCredentials: true,
          });
          setPositions(response.data.positions);
          setBusinesses(response.data.businesses);
      } catch (error) {
          console.error("Error fetching filter data:", error);
      }
  };

    fetchTimesheets();
    fetchFilters();
}, []);

useEffect(() => {
    const filtered = filteredData.filter(
        (item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            (selectedPosition ? item.position === selectedPosition : true) &&
            (selectedBusiness ? item.business === selectedBusiness : true)
    );
    setFilteredData(filtered);
}, [searchTerm, selectedPosition, selectedBusiness]);
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Link to={`/timesheet/${record.key}`}>
          <strong>{text}</strong>
        </Link>
      ),
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: 'Business',
      dataIndex: 'business',
      key: 'business',
    },
    {
      title: 'Total Hours',
      dataIndex: 'totalHours',
      key: 'totalHours',
    },
    {
      title: 'Week Submitted',
      dataIndex: 'date',
      key: 'weekSubmitted',
      render: (dates) => {
          const hasSubmitted = dates.some(dateObj => {
              const timesheetDate = moment(dateObj.date.$date);
              return timesheetDate.isBetween(moment().startOf('isoWeek'), moment().endOf('isoWeek'), null, '[]');
          });
          return hasSubmitted ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>;
      },
  },
    // {
    //   title: 'Total Jobs',
    //   dataIndex: 'totalJobs',
    //   key: 'totalJobs',
    // },
    // {
    //   title: 'Week Submitted',
    //   dataIndex: 'date',
    //   key: 'weekSubmitted',
    //   render: (dates) => {
    //     const hasSubmitted = hasSubmittedForCurrentWeek(dates);
    //     return hasSubmitted ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>;
    //   },
    // },
    // {
    //   title: 'Productivity Change (1W)',
    //   dataIndex: 'productivityChange',
    //   key: 'productivityChange',
    //   render: (change) => {
    //     let color = 'yellow';
    //     let icon = '⇔';
    //     if (change > 0) {
    //       color = 'green';
    //       icon = '↑';
    //     } else if (change < 0) {
    //       color = 'red';
    //       icon = '↓';
    //     }
    //     return <Tag color={color}>{`${icon} ${change}%`}</Tag>;
    //   },
    // },
    // {
    //   title: 'Productivity Score (This Week)',
    //   dataIndex: 'productivityScores',
    //   key: 'productivityScores',
    //   render: (scores) => (
    //     <div className="chart-container">
    //       <Line
    //         data={{
    //           labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    //           datasets: [
    //             {
    //               label: 'Productivity Score',
    //               data: scores,
    //               fill: false,
    //               borderColor: 'rgba(75,192,192,1)',
    //               borderWidth: 2,
    //               tension: 0.1,
    //             },
    //           ],
    //         }}
    //         options={{
    //           responsive: true,
    //           maintainAspectRatio: false,
    //           scales: {
    //             x: { display: false },
    //             y: { display: false },
    //           },
    //         }}
    //       />
    //     </div>
    //   ),
    // },
  ];

  return (
    <div className="manhour-statistics-page">
            {/* Tabs and top section */}
            <div className="top-section">
                <Tabs
                    activeKey={activeTab}
                    onChange={(key) => setActiveTab(key)}
                    className="custom-tabs"
                    type="card"
                >
                    <TabPane tab="Famz" key="famz">
                        {/* <div className="cards-section">
                            
                            <Card title="Top Performers" className="statistics-card">
                                <List
                                    itemLayout="horizontal"
                                    dataSource={topPerformersMockData}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={<Avatar src={item.imgUrl} />}
                                                title={item.name}
                                                description={`Productivity Score: ${item.score}`}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Card>

                            
                            <Card title="Upcoming Projects" className="statistics-card">
                                <List
                                    itemLayout="horizontal"
                                    dataSource={upcomingProjectsMockData}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={item.title}
                                                description={item.description}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Card>

                            
                            <Card title="Empty Section" className="statistics-card">
                                <p>Content coming soon...</p>
                            </Card>
                        </div> */}
                    </TabPane>

                    {/* <TabPane tab="Project" key="project">
                        <div>Project Tab Content</div>
                    </TabPane> */}
                </Tabs>
            </div>

            {/* Bottom section - shown only in the Famz tab */}
            {activeTab === 'famz' && (
                <>
                    <Divider />
                    <div className="filter-bar">
                        <Input placeholder="Search by name" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                        <Select placeholder="Filter by position" value={selectedPosition} onChange={setSelectedPosition} allowClear>
                            {positions.map((pos) => (
                                <Option key={pos} value={pos}>{pos}</Option>
                            ))}
                        </Select>
                        <Select placeholder="Filter by business" value={selectedBusiness} onChange={setSelectedBusiness} allowClear>
                            {businesses.map((bus) => (
                                <Option key={bus} value={bus}>{bus}</Option>
                            ))}
                        </Select>
                    </div>
                    <div className="statistics-table">
                        <Table columns={columns} dataSource={filteredData} pagination={false} scroll={{ x: 'max-content', y: 300 }} />
                    </div>
                </>
            )}
        </div>
    );
};

export default ManHourStatistics;