import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Sidebar.css';
import { handleLogout } from '../utils/auth';
import { useNavigate, NavLink } from 'react-router-dom';
import { RxCross2 } from 'react-icons/rx';
import { BiSolidDashboard } from "react-icons/bi";
import { IoIosLogOut } from "react-icons/io";
import { RiAdminFill } from "react-icons/ri";
import { BsBuildingFillAdd } from "react-icons/bs";
import { HiInformationCircle } from "react-icons/hi";
import { CgProfile } from "react-icons/cg";
import { VscPreview } from "react-icons/vsc";
import { ImStatsDots } from "react-icons/im";
import { IoMdPersonAdd } from "react-icons/io";
import { FaWpforms, FaArrowLeft } from "react-icons/fa";
import { FaTableCells } from "react-icons/fa6";
import BritesLogo from '../assets/Brites_logo.png';
import CampaignIcon from '../assets/campaing-removebg-preview.png';
import ProjectAssigeIcon from '../assets/project-assige.png';
import RequestFormsIcon from '../assets/Request.png';
import EvaluationIcon from '../assets/Evaluation.png';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const Sidebar = ({ isExpanded, toggleSidebar }) => {
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState([]);
  const [isAdminExpanded, setIsAdminExpanded] = useState(false);
  const [isRequestExpanded, setIsRequestExpanded] = useState(false);

  const hasAdminOrEditorRole = userRole.includes('ADMIN-CCD-TECH') || userRole.includes('CEO')|| userRole.includes('ADMIN-CCD')|| userRole.includes('STAT-VIEW');
  const hasAdminAddRole = userRole.includes('ADMIN-CCD-TECH') || userRole.includes('CEO');
  const hasHeadRole = userRole.includes('PROJECT-CHAMPION') || userRole.includes('MD') || userRole.includes('CEO')|| userRole.includes('ADMIN-CCD-TECH');
  const StateRole = userRole.includes('STAT-VIEW');
  const LeaveRole = userRole.includes('ADMIN-CCD-TECH') || userRole.includes('CEO')|| userRole.includes('ADMIN-CCD')|| userRole.includes('HEAD');
  const isHospitalityRole = userRole.includes('HOSPITALITY');
  
  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/users/role`, { withCredentials: true });
        setUserRole(response.data.role);
      } catch (error) {
        console.error('Failed to fetch user role:', error);
      }
    };

    fetchUserRole();
  }, []);
  console.log("isAdminExpanded",isAdminExpanded)

  const handleLogoutClick = async () => {
    try {
      await handleLogout();
      localStorage.removeItem('token');
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <aside className={isExpanded ? 'expanded' : 'narrow'}>
      <div className="top">
        <div className="logo">
          <img src={BritesLogo} alt="Brites Logo" />
        </div>
        <div className="close" id="close-btn" onClick={toggleSidebar}>
          <RxCross2 size={15}/>
        </div>
      </div>
      <div className="sidebar">
        <NavLink end to="/dashboard" activeClassName="active" onClick={toggleSidebar}>
          <span></span>
          <BiSolidDashboard size={25} />
          <h3>Dashboard</h3>
        </NavLink>
  
        {hasAdminOrEditorRole && (
          <>
            <div className="admin-manage-section">
              <div onClick={() => setIsAdminExpanded(!isAdminExpanded)} className="admin-manage-toggle">
                <span></span>
                <RiAdminFill size={25} />
                <h3>Admin Manage</h3>
                <h3>{isAdminExpanded}</h3>
              </div>
              {isAdminExpanded && (
                <div className="admin-manage-submenu">
                  {StateRole && (
                    <NavLink to="/dashboard/User-State" activeClassName="active-submenu" onClick={toggleSidebar}>
                      <span></span>
                      <ImStatsDots size={15} />
                      <h4>Leave Statistics</h4>
                    </NavLink> 
                  )}
                  {hasAdminAddRole && (
                    <NavLink to="/dashboard/AdminManager" activeClassName="active-submenu" onClick={toggleSidebar}>
                      <span></span>
                      <IoMdPersonAdd size={15} />
                      <h4>Add User</h4>
                    </NavLink>  
                  )}
                </div>
              )}
            </div>
            <NavLink to="/dashboard/Add-Business" activeClassName="active" onClick={toggleSidebar}>
              <span></span>
              <BsBuildingFillAdd size={25} />
              <h3>Add Business</h3>
            </NavLink>
          </>
        )}
        { (hasHeadRole || hasAdminOrEditorRole) && (
          <>
            <NavLink to="/dashboard/ProjectAssignment" activeClassName="active" onClick={toggleSidebar}>
              <span></span>
              <img src={ProjectAssigeIcon} alt="Dashboard" className="sidebar-icon" />
              <h3>Project Assign</h3>
            </NavLink>
            <NavLink to="/dashboard/manhour-stats" activeClassName="active" onClick={toggleSidebar}>
              <span></span>
              <img src={ProjectAssigeIcon} alt="Dashboard" className="sidebar-icon" />
              <h3>Manhour</h3>
            </NavLink>
          </>
          
        )}
         {LeaveRole && (
          <>
            <NavLink to="/dashboard/LeaveApproval" activeClassName="active" onClick={toggleSidebar}>
              <span></span>
              <VscPreview size={25} />
              <h3>Leave-Approval</h3>
            </NavLink>
          </>
        )}
        {!isHospitalityRole && (
          <>
            <NavLink to="/dashboard/My-Campaigns" activeClassName="active" onClick={toggleSidebar}>
              <span></span>
              <img src={CampaignIcon} alt="Dashboard" className="sidebar-icon" />
              <h3>My Campaigns</h3>
            </NavLink>

            <NavLink to="/dashboard/Evaluations" activeClassName="active" onClick={toggleSidebar}>
              <span></span>
              <img src={EvaluationIcon} alt="Dashboard" className="sidebar-icon" />
              <h3>Evaluations</h3>
            </NavLink>
          </>
        )}
        
        <div onClick={() => setIsRequestExpanded(!isRequestExpanded)} className="admin-manage-toggle">
          <span></span>
          <img src={RequestFormsIcon} alt="Request Forms" className="sidebar-icon" />
          <h3>Request Forms</h3>
        </div>
        {isRequestExpanded && (
          <div className="admin-manage-submenu">
            <NavLink to="/dashboard/Request-Forms" activeClassName="active-submenu" onClick={toggleSidebar}>
              <span></span>
              <FaWpforms size={18} />
              <h4>Request Forms</h4>
            </NavLink>
            {StateRole && (
              <NavLink to="/dashboard/Request-Review" activeClassName="active-submenu" onClick={toggleSidebar}>
                <span></span>
                <FaTableCells size={18} />
                <h4>Request Review</h4>
              </NavLink>
            )}
          </div>
        )}
        

        <NavLink to="/dashboard/information-center" activeClassName="active" onClick={toggleSidebar}>
          <span></span>
          <HiInformationCircle size={25} />
          <h3>Infomation-Center</h3>
        </NavLink>
        <NavLink to="/dashboard/Profile" activeClassName="active" onClick={toggleSidebar}>
          <span></span>
          <CgProfile size={25} />
          <h3>Profile</h3>
        </NavLink>
        <div className="logout-container">
            <a href="#" onClick={handleLogoutClick}>
                <span></span>
                <IoIosLogOut size={25}/>
                <h3>Logout</h3>
            </a>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
