import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BiDetail } from 'react-icons/bi';
import { Modal, Button, Input, Select, Pagination } from 'antd';
import '../styles/LeaveApproval.css';

const { Option } = Select;

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const LeaveApproval = () => {
  const [leaves, setLeaves] = useState([]);
  const [filteredLeaves, setFilteredLeaves] = useState([]);
  const [selectedLeave, setSelectedLeave] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(5);

  useEffect(() => {
    fetchLeaves();
  }, []);

  // const fetchLeaves = async () => {
  //   try {
  //     const response = await axios.get(`${API_BASE_URL}/api/users/leave-details`, { withCredentials: true });
  //     const sortedLeaves = response.data.sort((a, b) => new Date(a.fromDate) - new Date(b.fromDate));
  //     setLeaves(sortedLeaves);
  //     setFilteredLeaves(sortedLeaves);
  //   } catch (error) {
  //     console.error('Error fetching leaves:', error);
  //   }
  // };
  const fetchLeaves = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/users/leave-details`, { withCredentials: true });
      const today = new Date();
  
      // Separate future and past dates based on fromDateTime
      const parsedLeaves = response.data.map(leave => ({
        ...leave,
        fromDateTime: new Date(leave.fromDateTime)  // Ensure fromDateTime is a Date object
      }));
  
      const futureLeaves = parsedLeaves.filter(leave => leave.fromDateTime >= today);
      const pastLeaves = parsedLeaves.filter(leave => leave.fromDateTime < today);
  
      // Sort future dates in ascending order and past dates in descending order
      futureLeaves.sort((a, b) => a.fromDateTime - b.fromDateTime);
      pastLeaves.sort((a, b) => b.fromDateTime - a.fromDateTime);
  
      // Combine sorted future and past dates
      const sortedLeaves = [...futureLeaves, ...pastLeaves];
  
      setLeaves(sortedLeaves);
      setFilteredLeaves(sortedLeaves);
    } catch (error) {
      console.error('Error fetching leaves:', error);
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    filterLeaves(value, statusFilter);
  };

  const handleStatusFilterChange = (value) => {
    setStatusFilter(value);
    filterLeaves(searchTerm, value);
  };

  const filterLeaves = (searchTerm, statusFilter) => {
    let filtered = leaves;

    if (searchTerm) {
      filtered = filtered.filter((leave) =>
        (leave.firstName && leave.firstName.includes(searchTerm)) ||
        (leave.lastName && leave.lastName.includes(searchTerm)) ||
        (leave.nickname && leave.nickname.includes(searchTerm))
      );
    }

    if (statusFilter) {
      filtered = filtered.filter((leave) => leave.status === statusFilter);
    }

    setFilteredLeaves(filtered);
  };

  const handleCardClick = (leave) => {
    setSelectedLeave(leave);
  };

  const handleModalClose = () => {
    setSelectedLeave(null);
  };

  const handleApprove = async () => {
    try {
      await axios.post(`${API_BASE_URL}/api/users/approve-leave/${selectedLeave._id}`, {}, { withCredentials: true });
      fetchLeaves();
      setSelectedLeave(null);
    } catch (error) {
      console.error('Error approving leave:', error);
    }
  };

  const handleDecline = async () => {
    try {
      await axios.post(`${API_BASE_URL}/api/users/decline-leave/${selectedLeave._id}`, {}, { withCredentials: true });
      fetchLeaves();
      setSelectedLeave(null);
    } catch (error) {
      console.error('Error declining leave:', error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedLeaves = filteredLeaves.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <div className="leave-approval-page">
      <h1>Leave Approval</h1>
      <div className="search-filter-container">
        <Input
          type="text"
          placeholder="Search by name"
          value={searchTerm}
          onChange={handleSearchChange}
          className="search-input"
        />
        <Select
          placeholder="Filter by status"
          value={statusFilter}
          onChange={handleStatusFilterChange}
          className="status-filter"
        >
          <Option value="">All</Option>
          <Option value="อนุมัติ">อนุมัติ</Option>
          <Option value="รออนุมัติ">รออนุมัติ</Option>
          <Option value="ไม่อนุมัติ">ไม่อนุมัติ</Option>
          <Option value="ยกเลิก">ยกเลิก</Option>
        </Select>
      </div>
      <div className="leave-cards-container">
        {paginatedLeaves.map((leave) => (
          <div key={leave._id} className="leave-card" onClick={() => handleCardClick(leave)}>
            <div className="leave-card-header">
              <h3>{leave.purpose}</h3>
              <span className={`status ${leave.status}`}>{leave.status}</span>
            </div>
            <div className="leave-card-body">
              <p>{leave.firstName} {leave.lastName}</p>
              <p>วันที่เริ่มต้นลา: {leave.fromDate}</p>
              <p>วันที่ลาถึง: {leave.toDate}</p>
              {leave.leaveType === 'ครึ่งวัน' && (
                <p>เวลา: {leave.halfDayStartTime} - {leave.halfDayEndTime}</p>
              )}
              {leave.filePath && (
                <a href={`${API_BASE_URL}/${leave.filePath}`} target="_blank" rel="noopener noreferrer" className="download-button">Download File</a>
              )}
            </div>
            <div className="leave-card-footer">
              <Button type="primary" icon={<BiDetail />} onClick={() => handleCardClick(leave)}>Details</Button>
            </div>
          </div>
        ))}
      </div>
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={filteredLeaves.length}
        onChange={handlePageChange}
      />
      {selectedLeave && (
        <Modal
          visible={!!selectedLeave}
          onCancel={handleModalClose}
          footer={[
            <Button key="back" onClick={handleDecline}>Decline</Button>,
            <Button key="submit" type="primary" onClick={handleApprove}>Approve</Button>
          ]}
          className="leave-detail-modal"
        >
          <div className="leave-detail-card">
            <h2>Leave of Absence</h2>
            <p>You have been requested to review the following</p>
            <p><span>ชื่อ-นามสกุล (ภาษาไทย):</span> {selectedLeave.firstName} {selectedLeave.lastName}</p>
            <p><span>ตำแหน่ง:</span> {selectedLeave.position}</p>
            <p><span>วัตถุประสงค์การลา:</span> {selectedLeave.purpose}</p>
            <p><span>วันที่ยื่น:</span> {selectedLeave.createdAt}</p>
            <p><span>วันที่เริ่มต้นลา:</span> {selectedLeave.fromDate}</p>
            <p><span>วันที่ลาถึง:</span> {selectedLeave.toDate}</p>
            <p><span>Location:</span> {selectedLeave.location}</p>
            <p><span>รหัสพนักงาน:</span> {selectedLeave.player_code}</p>
            <p><span>หน่วยงาน:</span> {selectedLeave.unit}</p>
            <p><span>เงื่อนไขการลา:</span> รับทราบและยอมรับ</p>
            <p className="leave-condition">เงื่อนไขการลา: สมาชิกที่ต้องการจะลาหยุดจะต้องกรอกข้อมูลการลาในระบบ เพื่อให้ผู้บังคับบัชชารับทราบและยืนยันอนุมัติการลาทุกครั้ง</p>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default LeaveApproval;
