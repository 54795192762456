import React, { useState, useEffect } from 'react';
import { DatePicker, Table, Button } from 'antd';
import { useParams } from 'react-router-dom';
import { ReloadOutlined } from '@ant-design/icons'; // Import the refresh icon
import moment from 'moment';
import '../styles/UserTimesheetPage.css';
import dayjs from 'dayjs'; // Import dayjs
import isBetween from 'dayjs/plugin/isBetween';
import axios from 'axios';
dayjs.extend(isBetween);

const { RangePicker } = DatePicker;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const UserTimesheetPage = () => {
  const { userId } = useParams();
  const [timesheetData, setTimesheetData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [userName, setUserName] = useState({ firstName: '', lastName: '' });
  useEffect(() => {
    // Fetch the timesheet data for the user (Mock API call)
    fetchUserTimesheet(userId);
  }, [userId]);

  const fetchUserTimesheet = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/timesheet/user-timesheet/${userId}`, {
        withCredentials: true,
    });
      const { firstName, lastName, date } = response.data;
      setUserName({ firstName, lastName });
      setTimesheetData(date);
      setFilteredData(flattenTimesheetData(date));
    } catch (error) {
      console.error("Error fetching user timesheet data:", error);
    }
  };

  // const fetchUserTimesheet = () => {
  //   const mockTimesheetData = [
  //     {
  //       date: '2024-09-16T00:00:00.000+00:00',
  //       records: [
  //         {
  //           jobNumber: 'Project 3',
  //           task: 'Design',
  //           workingHours: 8,
  //           remark: 'test_remark',
  //         },
  //       ],
  //     },
  //     {
  //       date: '2024-09-17T00:00:00.000+00:00',
  //       records: [
  //         {
  //           jobNumber: 'Project 2',
  //           task: 'Strategic Planning',
  //           workingHours: 4,
  //           remark: '',
  //         },
  //       ],
  //     },
  //     {
  //       date: '2024-09-18T00:00:00.000+00:00',
  //       records: [
  //         {
  //           jobNumber: 'Project 3',
  //           task: 'Coordination',
  //           workingHours: 5,
  //           remark: 'remark_test',
  //         },
  //       ],
  //     },
  //     {
  //       date: '2024-09-30T00:00:00.000+00:00',
  //       records: [
  //         {
  //           jobNumber: '080',
  //           task: 'Meeting with Internal Team',
  //           workingHours: 4,
  //           remark: '',
  //         },
  //       ],
  //     },
  //   ];

  //   setTimesheetData(mockTimesheetData);
  //   setFilteredData(flattenTimesheetData(mockTimesheetData));
  // };

  const flattenTimesheetData = (data) => {
    return data.flatMap((entry) =>
      entry.records.map((record) => ({
        date: entry.date,
        ...record,
      }))
    );
  };

  const handleDateRangeChange = (dates) => {
    if (!dates || dates.length === 0) {
      setFilteredData(flattenTimesheetData(timesheetData));
      return;
    }

    const [startDate, endDate] = dates;
    const formattedStartDate = dayjs(startDate).startOf('day');
    const formattedEndDate = dayjs(endDate).endOf('day');

    const filtered = timesheetData.filter((entry) => {
      const entryDate = dayjs(entry.date).startOf('day');
      return entryDate.isBetween(formattedStartDate, formattedEndDate, null, '[]');
    });

    setFilteredData(flattenTimesheetData(filtered));
  };

  const handleRefresh = () => {
    setSelectedDateRange([null, null]);
    setFilteredData(flattenTimesheetData(timesheetData));
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text) => moment(text).format('YYYY-MM-DD'),
    },
    {
      title: 'Day',
      key: 'day',
      render: (text, record) => moment(record.date).format('dddd'), // Calculate the day of the week
    },
    {
      title: 'Task',
      dataIndex: 'task',
      key: 'task',
    },
    {
      title: 'Job Number',
      dataIndex: 'jobNumber',
      key: 'jobNumber',
    },
    {
      title: 'Working Hours',
      dataIndex: 'workingHours',
      key: 'workingHours',
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
    },
  ];

  return (
    <div className="user-timesheet-page">
      <h2>Timesheet for {userName.firstName} {userName.lastName}</h2>

      {/* Date filter */}
      <div className="date-filter">
        <RangePicker
          value={selectedDateRange}
          onChange={handleDateRangeChange}
          allowClear
        />
        <Button
          onClick={handleRefresh}
          type="primary"
          icon={<ReloadOutlined />} // Use the reload icon here
          shape="circle"
          style={{ marginLeft: '10px' }}
        />
      </div>

      {/* Timesheet table */}
      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={false}
        rowKey="date"
        style={{ width: '100%' }} // Ensure table is full width
      />
    </div>
  );
};

export default UserTimesheetPage;
